// Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer" style={{width:"100%"}}>
      <p>Get the latest Lifewink App for your phone</p>
      <div className="store_container">
        <a href="https://play.google.com/store/apps" target="_blank" rel="noopener noreferrer">
          <img src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/google_play.png" alt="play store icon" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/app_store.png" alt="app store icon" />
        </a>
      </div>
      <p className="paragraph_two">
        If you have any questions, please email us at <a href="mailto: support@lifewink.com">support@lifewink.com</a> or visit our FAQs.
      </p>
      <div className="footer-links">
        <Link to="/about-us" className="footer-link">About Us</Link>  {/* Link to About Us page */}
        <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>  {/* Link to Privacy Policy page */}
      </div>
      <div className="social_icon">
        <a href="#"><img src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/Twitter.png" alt="Twitter" /></a>
        <a href="#"><img src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/facebook.png" alt="Facebook" /></a>
        <a href="#"><img src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/Instagram.png" alt="Instagram" /></a>
      </div>
      <a href="https://lifewink.com/" className="lifewink_link">www.lifewink.com</a>
    </div>
  );
};

export default Footer;
