import React from 'react';
import "./Privacy.css"
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'; // Import Link for routing

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div className="header">
               <Link to="/"> <img
                    src="https://lifewink-common.s3.us-east-2.amazonaws.com/emailAssets/lifewink_logo.png"
                    className="lifewink_logo"
                    alt="LifeWink Logo"
                />
                </Link>
            </div>
            <div className="content">
                <h1 style={{ paddingBottom: '.7rem' }}>Privacy Policy</h1>
                <h1>Thank you for using Lifewink</h1>
                <p>
                    We make it our mission to provide innovative communication technologies that carry forth the greatest gifts we have in life – to love and be loved. Our users (“you”) are at the center of everything we do here at Lifewink and we will never take the immense trust you place in our platform for granted.
                </p>
                <p>
                    Earning your trust is of utmost importance to us. We understand that this Policy represents one step towards earning that trust, but that it is really our actions and compliance with this Policy that counts the most.
                </p>
                <p>
                    We wrote this policy to help you understand what types of information we collect, how we use it to administer the Lifewink platform, and what choices you have about it. Some of the concepts covered in it are quite technical, and others involve legal terminology, but we hope that we’ve tried to explain them as simply and clearly as we can.
                </p>
                <p>
                    We may make changes to this Policy from time to time. If we do, we’ll identify any changes on this page, unless any changes significantly impact this Policy (in which case, we’ll notify you by email). If you continue using any Services after those changes have been made, they will apply to you.
                </p>
                <h1>What does this Privacy Policy cover?</h1>
                <p>
                    This is Lifewink’s Privacy Policy for users residing in the United States. It describes how we collect, use, and disclose your Personal Information through your interactions with us through our services, products, communications, and websites that refer to this Privacy Policy and describes our practices for collecting, using, maintaining, protecting, and disclosing that information. When we say <strong>“Personal Information”</strong> in this Policy, we mean any information that can identify, relate to, describe, or be associated with you, directly or indirectly.
                </p>
                <p>
                    It also describes how you can exercise your rights under the California Consumer Privacy Act, the Colorado Privacy Act, the Connecticut Act Concerning Personal Data Privacy and Online Monitoring, the Utah Consumer Privacy Act, and the Virginia Consumer Data Protection Act. We call those laws the <strong>“U.S. Privacy Laws.”</strong>
                </p>
                <div className="container-text">
                    <h1>We Collect Information in a Few Different Ways</h1>
                    <div className="section">
                        <h3>1. Information You Give to Us</h3>
                        <p>
                            When you use any Lifewink websites, apps, platform, services, technologies, or any other products of features we offer (<strong>“Lifewink”</strong> or the <strong>“Services”</strong>) or contact us or speak with our customer service teams, you may voluntarily share Personal Information with us during those interactions.
                        </p>
                        <p>Here are some examples of ways you might voluntarily provide Lifewink with Personal Information:</p>
                        <ul>
                            <li><strong>Account Information:</strong> when you join Lifewink and create an account with us, we collect information from you like your name, email address, phone number, and mailing address.</li>
                            <li><strong>Lifewinks:</strong> when you create, save, upload, and send messages and stories communicated by text, audio, video, and photographic content (we call each message a <strong>“Lifewink”</strong>).</li>
                            <li><strong>Recipients, Guardians, and Stewards:</strong> when you designate any recipients of a Lifewink message (<strong>“Recipients”</strong>), individuals responsible for notifying Lifewink of any triggering life events (<strong>“Guardians”</strong> and <strong>“Stewards,”</strong> depending on the type of life event), Lifewink will process certain information about those individuals too.</li>
                            <li><strong>Precise location information:</strong> depending on where you live, you can choose to share your precise location using your device settings.</li>
                        </ul>
                    </div>
                    <div className="section">
                        <h3>2. Information We Collect Through Automatic Data Collection Technologies</h3>
                        <p>
                            As you navigate through and interact with the Services, certain automatic internet and electronic network activity information gets created and logged automatically.
                        </p>
                        <p>Here are some examples of the types of automatic tracking information we collect from you:</p>
                        <ul>
                            <li><strong>Tracking technology information:</strong> We use “cookies” and similar technologies, like web beacons, to track and store your preferences and activity, like your login status and information, session data, or interactions with specific websites or web pages. We may also use this data to make inferences about you and your preferences.</li>
                            <li><strong>Location information:</strong> we use your IP address to approximate your location, even if you don’t choose to share your precise location. Content incorporated into your Lifewinks may also include information about your location.</li>
                            <li><strong>Log data:</strong> when you use our Services, our servers automatically record information (<strong>“Log Data”</strong>), like information about your computer and internet connection, including your IP address, operating system, and browser type, activity on the websites or platform, and the time and date when you accessed the Services.</li>
                        </ul>
                    </div>
                    <div className="section">
                        <h3>3. Partners and Advertisers</h3>
                        <p>
                            We also receive information about you and your activity from our advertisers, partners, and other third parties. To help you see advertisements that you’re more likely to be interested in, Lifewink or one of Lifewink’s third-party advertising partners may use information about your off-Lifewink activity to customize the ads that are shown to you.
                        </p>
                        <p>For example:</p>
                        <ul>
                            <li>A company advertising on Lifewink might share a list of customer email addresses with us. If their customers are on Lifewink, we can show them more relevant ads.</li>
                            <li>We also receive information about your online and offline actions, and purchases from third-party data providers who have the rights to provide us with your information. These partners collect your information when you visit their websites or use their services or through third parties they work with.</li>
                        </ul>
                        <p>We require each of these partners to have rights to collect, use, and disclose your information before providing any information to us.</p>
                        <p>
                            You can control how we use this information to personalize your experience and the ads you see on Lifewink in your <a href="#">Privacy and Data Settings</a>.
                        </p>
                    </div>
                    <div className="container-text">
    <h1>How We Use and Disclose Your Information</h1>

    <p>
        The Personal Information we collect, use, and disclose about you will vary depending on how you interact with our Services.
        Here is a summary of the types of Personal Information we may have collected about you, how we may use that Personal Information,
        and with whom we disclose that Personal Information:
    </p>

    <table className="info-table">
        <thead>
            <tr>
                <th>Categories of Personal Information</th>
                <th>Examples of How We Use Personal Information</th>
                <th>Parties with Whom Each Category of Personal Information May Be Disclosed</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Identifiers (name, email address, phone number, mailing address)</td>
                <td>Providing, personalizing, and improving our products, including ads</td>
                <td>People and accounts you share and communicate with (e.g., Recipients, Guardians and Stewards)</td>
            </tr>
            <tr>
                <td>Photos and videos, which may include face imagery</td>
                <td>Providing measurement, analytics, and other business services</td>
                <td>New owners in the event of a change of control or ownership of Lifewink</td>
            </tr>
            <tr>
                <td>Location-related information</td>
                <td>Providing marketing communications to you</td>
                <td>Partners, including advertising partners</td>
            </tr>
            <tr>
                <td>Audio or visual information, including photos, videos and voice recordings</td>
                <td>Communicating with you</td>
                <td>Service providers, like our payment processors and marketing providers</td>
            </tr>
            <tr>
                <td>Internet or other electronic network activity information</td>
                <td>For any sensitive personal information we collect, will only use or disclose it with your specific consent, or otherwise permitted by law.</td>
                <td>Law enforcement or other third parties in connection with any legal request, to comply with applicable law</td>
            </tr>
            <tr>
                <td>Information derived or inferred from other Personal Information about you (e.g., preferences, interests, other information used to personalize your experience on Lifewink)</td>
                <td colSpan="2"></td>
            </tr>
            <tr>
                <td>Payment information</td>
                <td colSpan="2"></td>
            </tr>
            <tr>
                <td>Other information you provide</td>
                <td colSpan="2"></td>
            </tr>
            <tr>
                <td>
                    <p>
                        We may also collect sensitive personal information (as defined in U.S. Privacy Laws) like:
                    </p>
                    <ul>
                        <li>Precise geolocation</li>
                        <li>Racial or ethnic origin, religious views</li>
                        <li>The content of messages you send and receive (which is considered sensitive personal information under the CCPA)</li>
                        <li>Sexual orientation</li>
                        <li>Health information</li>
                        <li>Face imagery or voice recordings</li>
                    </ul>
                </td>
                <td colSpan="2"></td>
            </tr>
        </tbody>
    </table>
    <p>
        We keep Personal Information, including sensitive Personal Information, as long as we need it to provide the Services, comply with legal obligations or protect our or others' interests.
        We decide how long we need information on a case-by-case basis.
    </p>
    <p>Here’s what we consider when we decide:</p>
    <ul>
        <li>If we need it to operate or provide the Services.</li>
        <li>The feature we use it for, and how that feature works.</li>
        <li>How long we need to retain the information to comply with certain legal obligations.</li>
    </ul>
    <p>If we need it for other legitimate purposes, such as to prevent harm; investigate possible violations of our terms or policies; promote safety, security and integrity; or protect ourselves, including our rights, property or products.</p>
</div>
<div className="container-text">
    <h1>Your Privacy Rights</h1>
    <p>
        Depending on where you live, you may have the right to request:
    </p>
    <ul>
        <li>Information about the Personal Information Lifewink has collected, used, and disclosed about you.</li>
        <li>Deletion of your Personal Information.</li>
        <li>Correction of your Personal Information.</li>
        <li>Transmission of your Personal Information to another controller.</li>
        <li>Withdrawal of your consent at any time or object to any use of your Personal Information.</li>
    </ul>
    <p>
        To exercise these rights, send us an email at <a href="mailto: support@lifewink.com">support@lifewink.com</a>. We may not be able to accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. If that happens, we’ll let you know. We may also need you to provide additional information for us to verify your identity before implementing or acting on a request.
    </p>
    {/* <p>
        To opt-out of targeted advertising, visit the <a href="[LINK TO PRIVACY OPT-OUT LINK]">Privacy Opt-Out Link</a>.
    </p> */}

    <h2>Contact Information</h2>
    <p>
        To ask questions or comment about this privacy policy and our privacy practices, the best way to contact us is by email: <a href="mailto: support@lifewink.com">support@lifewink.com</a>. You can also contact us at Lifewink LLC.
    </p>

    {/* <p className="effective-date">
        <strong>Effective Date in US:</strong> [DATE]
    </p> */}
</div>

                </div>
                {/* Additional sections and content can be similarly converted */}
            </div>
            <Footer/>
        </div>
    );
};

export default PrivacyPolicy;
